body {
  .od-search {
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .oppprint-menu {
    margin-right: 128px;
    &.collapsed {
      margin-right: 20px;
    }
    @media screen and(max-width: 767px) {
      margin-right: 5px;
    }
  }
  .oppprint-site-select {
    @media screen and(max-width: 767px) {
      min-width: 160px !important;
    }
  }
  .oppprint-site-select {
    @media screen and(max-width: 400px) {
      min-width: 140px !important;
    }
  }
  @media screen and(max-width: 540px) {
    .ant-calendar {
      width: 100%;
      font-size: 12px;
    }
    .ant-calendar-range {
      width: 100%;
      max-width: 100%;
    }
  }
  @media screen and(max-width: 376px) {
    .filter-and-date-range {
      .range-picker {
        width: 221px !important;
      }
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff9100;
  }
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: #ff9100;
  }
  .ant-calendar-range
    .ant-calendar-selected-start-date
    .ant-calendar-date:hover,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
    background: #ff9100;
  }
  .ant-calendar-today .ant-calendar-date {
    color: #ff9100;
    font-weight: bold;
    border-color: #ff9100;
  }
  .ant-btn-link {
    color: #ff9100;
  }
  .ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    padding: 21px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
  }

  .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    padding: 10px;

    label {
      font-weight: normal;
    }
  }

  .ant-btn.ant-btn-primary {
    background-color: #ff9100;
    border-color: #ff9100;
  }

  a {
    color: #ffc000;
    &:hover {
      color: #ff9100 !important;
    }
  }
  .ant-drawer-header {
    padding: 19px;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #ff9100;
  }
  .ant-menu-item > a:hover {
    color: #ff9100;
  }
  .ant-menu-submenu-title:hover {
    color: #ff9100;
  }

  .ant-menu-submenu-arrow:hover {
    color: #ff9100;
  }

  .ant-calendar-date:hover {
    background: rgb(253, 234, 193);
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::before {
    color: #ff9100;
  }
  .ant-menu-submenu-popup {
    background-color: white;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #ff9100;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ff9100 !important;
    opacity: 1.1 !important;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff9100;
  }
  .ant-select-selection:hover {
    border-color: #ff9100;
  }
  .ant-select-selection:before {
    border-color: #ff9100;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title
    .ant-menu-submenu-arrow::after {
    color: #ff9100;
  }
  .ant-pagination-item-active {
    border-color: #ff9100;
  }
  .ant-pagination-item-active a {
    color: #ff9100;
  }
  .ant-pagination-item-active:hover {
    border-color: #ff9100;
  }
  .ant-pagination-item-active:hover a {
    color: #ff9100;
  }
  .ant-spin-dot-item {
    background-color: #ff9100;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff9100;
    border-color: #ff9100;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #ff9100;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover {
    color: #ff9100;
  }

  body .ant-select-selection:hover {
    border-color: #ff9100;
  }

  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #ff9100;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ff9100;
  }

  .ant-input:hover {
    border-color: #ff9100;
  }

  .ant-input:focus {
  }
  .ant-input:focus {
    border-color: #ff9100;
    border-right-width: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .hblYke .anticon.pod-check {
    background-color: #ff9100;
  }
  .ant-dropdown-menu-item a span {
    color: #ff9100 !important;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #ff9100;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #ff9100;
    border-color: #ff9100;
  }

  .ant-btn-primary {
    color: #ffffff;
  }

  .ant-btn-primary:focus,
  .ant-btn-primary:hover {
    color: #ffffff;
  }

  .od-search .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-radius: 0;
  }

  .search-field .ant-select-selection--single {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .ant-select-selection:hover {
    border-color: #ff9100;
  }

  .ant-calendar-picker:hover
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #ff9100;
  }

  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #ff9100;
  }

  .ant-pagination-item-active a {
    color: #ff9100;
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #ff9100;
  }

  .ant-pagination-item-active {
    border-color: #ffc000;
  }

  .ant-tabs-ink-bar {
    background-color: #ff9100;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-active:hover,
  .ant-menu-submenu-title:hover {
    color: #ff9100;
  }

  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #ff9100;
    border-color: #ff9100;
  }

  .ant-menu-submenu-selected {
    color: #ff9100;
  }

  .ant-calendar-date:active {
    background: #ff9100;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: #fbdc9a;
  }

  .ant-btn-background-ghost.ant-btn-primary {
    color: #ff9100;
    border-color: #ff9100;
  }

  .ant-pagination-item-active a {
    color: #ff9100;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #ff9100;
    border-right-width: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: none;
    background: none;
    border-color: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff9100;
  }
  .ant-checkbox-checked::after {
    border: 1px solid #ff9100;
  }
  .ant-menu-submenu > .ant-menu {
    background-color: transparent;
    border-radius: 4px;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after {
    background: #ff9100;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: #ff9100;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #ff9100;
  }
  .ant-input-group-addon:hover {
    border: 1px solid #ff9100;
    cursor: pointer;
    span {
      color: #ff9100;
    }
  }

  @media only screen and (max-width: 768px) {
    .ant-drawer-right .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    // .ant-calendar-date-panel{
    //   display: grid
    // }
    // .ant-calendar-body {
    //   width: 280px;
    // }
    // .ant-calendar {
    //   width: 280px
    // }
  }
  .ant-modal-body {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
  .detail-order {
    line-height: 3;

    .order-item {
      margin: 30px 0px;
      border: 1px solid rgb(219, 210, 210);
      border-width: 1px 0 1px;

      .ant-card-bordered {
        border: unset;
      }

      .titel-item {
        margin: 0px 12px;
      }

      .list-item {
        border-top: 1px solid rgb(219, 210, 210);

        .image img {
          width: 70px;
          margin: 10px 0px;
          justify-content: center;
          border: 1px solid #f5f5f5;
          text-align: center;
          border-radius: 5px;
          height: auto;
        }

        .title-sku {
          color: #ccc;
          margin: 10px 0px;
        }
      }
    }
  }

  .attribute {
    .ant-table-tbody {
      box-sizing: unset;
      transition: unset;

      .ant-table-row-level-0 {
        transition: unset;
        box-sizing: unset;

        .ant-table-row-cell-break-word {
          transition: unset;

          .editable-cell-value-wrap {
            text-align: left;
          }
        }
      }
    }
  }
}

.ant-table-content {
  .ant-table-body {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-bottom: none;

    tr td {
      padding: 10px 15px;

      p {
        margin: 0;
      }
    }
  }

  .ant-table-thead {
    tr th {
      color: #ff9100;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.ant-layout-footer {
  padding: 15px;
  text-align: center;
}

.ant-card-head {
  min-height: 40px;
  padding: 0 15px;

  .ant-card-head-title {
    padding: 10px 0;
  }
}

.ant-card-body {
  padding: 15px;

  .ant-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-form-item-label {
  font-weight: 500;
  line-height: 25px;
}

.ant-form-item-required {
  &:before {
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.ant-form-horizontal {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.product-type-modal {
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0;
    max-height: calc(100vh - 235px);

    .ant-table-content .ant-table-body tr td {
      padding: 5px 10px;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }
}

.ant-table-content .ant-table-body {
  border: 0 none;
}

.ant-table-tbody {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.product-variant-tag {
  position: absolute;
  top: -44px;
  left: 40px;
}

.product-variants .ant-table-footer {
  padding: 15px 0;
}

.ant-layout-sider {
  background-color: #f6f8f9;
  border-bottom: 1px solid #e0e6ed;

  .ant-menu-light {
    background-color: #f6f8f9;
    border-right: 0 none;
  }
}
.ant-table-fixed {
  .ant-table-tbody {
    > tr > td {
      padding: 10px 15px;
    }
  }
}
.ant-layout-content {
  border-left: 1px solid #e0e6ed;
}

.ant-form.ant-form-vertical {
  .ant-row {
    margin-bottom: 10px;

    .ant-form-item-label {
      line-height: 25px;
    }

    label {
      font-weight: 500;
      padding-right: 15px;

      &:before {
        position: absolute;
        right: 0;
      }

      &:after {
        display: none;
      }
    }
  }
}
.ant-form.ant-form-vertical {
  .ant-row {
    table {
      label {
        padding-right: 0px;
      }
      tr > th.ant-table-selection-column {
        padding-right: 15px;
      }
    }
  }
}
.ant-layout-header {
  height: 60px;
  line-height: 60px;
}

.pod-search-box input {
  font-size: 12px;
  background-color: rgba(81, 83, 101, 0.28);
  border: 1px solid rgba(81, 83, 101, 0.28);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
}

@media screen and(max-width: 991px) {
  .ant-drawer-wrapper-body {
    .left-menu {
      height: 100vh !important;
      background: #fff;
      padding-top: 0 !important;
    }
  }
}

.mapping-orders .ant-modal-body {
  padding-top: 10px;
}
//Artwork
.p-artwork-category {
  .ant-card {
    border-radius: 0px !important;
  }

  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    border-left: none;
    border-right: none;
  }

  .ant-card-head {
    border: none;
  }

  .ant-card-body {
    padding: 0px 24px !important;
  }
}
.header-filter {
  display: grid;
  gap: 5px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px;
  outline: 0.1rem solid transparent;
}

//customize style
.custom-action:hover .custom-action-show {
  display: flex;
}
.delete-button-color {
  color: #f5222d !important;
}
.align-right {
  text-align: right;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.m-15 {
  margin: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-24 {
  padding: 24px;
}

.p-15-24 {
  padding: 15px 24px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-fill {
  width: -webkit-fill-available;
}

.width-300 {
  width: 300px;
}

.width-200 {
  width: 200px;
}

.width-150 {
  width: 150px;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.align-center {
  text-align: center;
}

.item-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.content-center {
  justify-content: center;
}
.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}
.w100 {
  width: 100%;
}
.ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
}

.p-cliparts,
.p-artworks {
  .ant-upload.ant-upload-drag {
    border: 0 !important;
    padding: 0;
    .ant-upload {
      padding: 0;
      border: 0 !important;
    }
  }
}

.screen-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ddd;
  opacity: 0.9;
  font-size: 40;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;

  &:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(255, 145, 0);
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

@keyframes bounce {
  0% {
    top: 37px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}
.p-clipart-category {
  .tree-transparent {
    background-color: #f0f2f5;
    .ant-tree-switcher-leaf-line {
      background-color: #f0f2f5;
    }
    .ant-tree-switcher {
      background-color: #f0f2f5;
    }
  }
}
.ant-btn-link:hover {
  border-color: transparent !important;
}
.ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
}
.ant-modal.modal-fixed .ant-modal-content {
  position: fixed;
  width: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
}
.ant-btn-link:focus {
  border-color: transparent !important;
}
.modal-choose-artwork {
  top: 30px;

  .ant-modal-body {
    padding: 10px;
  }
}
.clipart {
  position: relative;
  height: 0px;
  padding-bottom: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgb(245, 245, 245);
}

.ant-page-header {
  &.artwork-page-header {
    .ant-page-header-heading {
      justify-content: center;
    }
  }
}

.left-toolbar.dark {
  background-color: #efefef;

  * {
    color: #000;
  }

  filter: invert(100%);

  img {
    filter: invert(100%);
  }

  .ant-checkbox {
    filter: invert(100%);
  }
}

#artwork-design-sidebar,
#mockup-design-sidebar {
  .action-icons {
    svg {
      font-size: 15px;
    }
  }
  .site-input-number-wrapper {
    svg {
      font-size: 10px;
    }
  }

  .layer-list {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      border-bottom: 1px solid #d9d9d9;

      &:last-child {
        border-bottom: none;
      }

      &.draging {
        border-bottom: 1px solid #d9d9d9;
        border-top: 1px solid #d9d9d9;
        background-color: #fff;
      }
    }

    .ant-collapse-content-box {
      .layer-list {
        margin-right: -15px;
      }
    }
  }

  .ant-collapse-item {
    &.Image,
    &.Text,
    &.Printarea,
    &.Option {
      .ant-collapse-content {
        display: none;
      }
    }

    &.Group {
      &.shared-layer {
        .ant-collapse-content {
          display: none;
        }
      }

      > .ant-collapse-content > .ant-collapse-content-box {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .ant-collapse-header {
    padding-top: 8px;
    padding-bottom: 8px;

    .ant-collapse-arrow {
      top: 14px;
      padding-top: 0;
    }
  }

  .layer-title {
    position: relative;
    font-size: 12px;
    font-weight: 200;

    .layer-icon {
      margin-right: 7px;
      font-size: 16px;
    }

    input {
      position: absolute;
      width: 150px;
      top: -9px;
    }
  }

  .ant-collapse
    > .ant-collapse-item:not(.ant-collapse-no-arrow)
    > .ant-collapse-header {
    padding-left: 30px;
  }

  .ant-collapse-header {
    .ant-collapse-arrow {
      left: 15px;
    }

    &[aria-expanded="true"] {
      .layer-title {
        span {
          font-weight: 600;
          color: var(--primary-color);
        }
      }
    }

    .edit {
      display: none;
    }

    &:hover {
      .edit {
        display: initial;
      }
    }
  }
}

.ant-form-horizontal .ant-form-item-control {
  &.ant-col-24 {
    flex: 0 0 100%;
  }
}

.ant-page-header {
  &.artwork-page-header {
    .ant-page-header-heading {
      justify-content: center;
    }
  }
}

li.draging {
  z-index: 9999;
}

.sketch-picker {
  &.no-shadow {
    box-shadow: none !important;
    padding: 0 !important;
  }
}

.ant-form-horizontal {
  &.personalized-form {
    .ant-form-item {
      .ant-form-item-label {
        flex: 0 0 100%;
        text-align: left;
      }

      .ant-form-item-control-wrapper {
        flex: 0 0 100%;
      }
    }
  }
}
.upload-font {
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
  .ant-upload.ant-upload-drag {
    border: none;
  }
}

.tree-categories {
  .ant-tree-node-content-wrapper,
  .ant-tree-node-selected {
    width: 100%;
  }

  .ant-tree-treenode-selected .p-title-category {
    font-weight: 500;
    color: #5c6ac4;
  }

  .ant-tree-treenode-selected .p-title-category a {
    font-weight: 100;
  }

  .ant-tree-treenode {
    width: 100%;
    margin: 2px 0;
  }

  .p-title-category {
    display: flex;
    justify-content: space-between;
  }

  .ant-tree-list-holder-inner {
    display: initial !important;
  }
}
.cp-prewview-image {
  position: absolute;
  background: black;
  height: 100px;
  width: 100px;
  top: 0;
  opacity: 0.6;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
}
.cp-image {
  position: relative;
  z-index: 10;
  cursor: pointer;
  &:hover {
    .cp-prewview-image {
      display: flex;
    }
  }
}

button.ant-btn.ant-btn-icon-only {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.ant-message-loading svg {
  color: #ff9100;
}

.box_color_custom_map > div{
  padding-bottom: 0px !important;
}

.Group_item_custom_map {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
}

.Group_item_custom_map > div {
  width: 50%;
  margin-left: 5px;
}

input.ant-input.colorInputSetting {
  width: 70px;
  height: 35px;
  padding: 0;
  cursor: pointer;
  margin-left: 10px;
}
.Type-color, .Type-visibility, .Type-hue{
  display: none;
}

.list_style_setting {
  padding-left: 16px !important;
  margin-bottom: 10px;
}

select.select-visibility {
  height: 35px;
  border: 1px solid #c1c1c1;
  background: #ffffff;
  border-radius: 3px;
  width: 100px;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

label.title_style_setting {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

li.li_group_item_style {
  font-size: 14px;
  font-weight: 800;
  text-transform: capitalize;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.codeJson-Textarea {
  width: 100%;
  height: 60vh;
  padding: 10px 10px;
  border: 1px solid #969696;
  border-radius: 4px;
  outline: none;
}

.header_settings_modal {
  background: #f2f2f2;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  z-index: 999999999999999;
}

.modal_setting .ant-modal-body {
  padding: 0px !important;
}

.setting_body_ol {
  padding: 0 48px;
  height: 60vh;
  overflow: auto;
  margin-bottom: 15px;
}

.body_modal_setting {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  height: 100%;
}

.body_modal_setting_item1 {
  width: 45%;
}
.controller_map_header{
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body_modal_setting_item2 {
  width: 55%;
  position: relative;
  overflow: auto;
  aspect-ratio: 1 / 1;
}

.input_search_google_map{
  height: 33px;
  width: 222px;
  font-size: 14px;
  padding: 0px 7px;
  border: none;
  box-shadow: 0px 0px 2px #515151;
  outline: none;
  border-radius: 2px;
  max-width: 100%;
}
.inputFileMarker{
  display: none;
}
.BtnChooseMarker{
  height: 33px;
  border: none;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 5px;
  padding: 0px 8px;
  outline: none;
  cursor: pointer;
}
.market_input_box{
  position: relative;
}
.btnRemoveMarker{
  position: absolute;
  top: -5px;
  right: -5px;
  background: #454545;
  color: #f4f4f4;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.btn_camera{
  height: 33px;
  width: 33px;
  margin-left: 5px;
  background: #ffffff;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 0px 0px 2px #515151;
  color: #313131;
  cursor: pointer;
}

.select_option_map_crop{
  margin-bottom: 15px;
  width: 135px;
}

.select_type_map_layer {
  margin: 0;
  width: 150px;
  margin-right: 10px;
}

.hdev-shape-image-dom {
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0.5;
  display: none;
}

.hdev-shape-image-dom.show{
  display: block !important;
}

.hdev-shape-image-dom.hide{
  display: none !important;
}
.ant-collapse-item.Label .ant-collapse-content-active{
  display: none !important;
}
.ant-card.ant-card-bordered.selected .ant-card-meta-description{
  color: #000000 !important;
  font-weight: bold;
}

.coordinates-header-view{
  height: 33px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  padding: 0 10px;
  font-size: 14px;
  box-shadow: 0px 0px 2px #515151;
  border-radius: 2px;
  cursor: pointer;
}

.box-modal-choose-type-lyric{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center
}
.box-modal-choose-spiral-type-lyric{
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center
}
.box-modal-choose-type-lyric-item {
  height: auto;
  padding: 0;
  margin: 10px 10px;
  position: relative
}
.div-title-type-lyric {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: #00000082;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #dedede;
  text-transform: uppercase;
}

.img-type-lyric-choose{
  width: 99px;
  height: 99px;
  overflow: hidden;
}

.box-control-lyric-modal > * {
  margin-right: 10px;
}

.box-color-lyrix{
  background: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  border-radius: 3px;
}
.box-color-lyrix button{
  padding: 2.5px 5px;
}
.box-control-lyric-modal {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
  padding: 10px;
}

.box-modal-choose-type-lyric .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  border: none;
  box-shadow: 0px 0px 4px 3px #ff9100;
}

.box-modal-choose-spiral-type-lyric .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  border: none;
  background: #00000073;
  box-shadow: 0px 0px 4px 3px #ff9100
}

.box-modal-choose-spiral-type-lyric .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  border: none;
  box-shadow: 0px 0px 4px 3px #ff9100;
}

.box-modal-choose-spiral-type-lyric .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
  box-shadow: 0px 0px 4px 3px #ff9100;
}

.box-modal-choose-type-lyric .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #ff9100;
}

.box-modal-choose-type-lyric .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
   color: #fff;
   background: #ff9100;
   border-color: #ff9100;
   box-shadow: 0px 0px 5px 2px #ff9100;
 }

.box-modal-choose-spiral-type-lyric .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
  //background-color: #ff9100 !important;
  //box-shadow: -2px 0px 6px 1px #ff9100;
  display: none;
}

.modal-choose-type-lyric .ant-modal-footer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-choose-type-lyric .ant-modal-footer > *{
  margin: 0px 5px;
}

.modal-choose-type-lyric .ant-modal-footer button{
  width: 100px;
}

.button-hidden-group{
  display: none;
}

.button-close-upload-file {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #3c3c3c;
  color: #ffffff;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}

.button-close-upload-file:hover{
  background: brown;
}

.ant-steps-item-finish .ant-steps-item-icon{
  border-color: #ff9100;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ff9100;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #ff9100;
}

.ant-steps-item-process .ant-steps-item-icon{
  border-color: #ff9100;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #ff9100;
}

.ant-tabs-content-holder {
  max-height: 70vh;
  overflow: auto;
}

.ant-tabs-content-holder::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #F5F5F5;
} 

.ant-tabs-content-holder::-webkit-scrollbar-thumb {
  background-color: #ff9100;
}


.add_new_artwork_select_size {
  display: flex;
  align-content: flex-start;
  flex-wrap: nowrap;
  overflow: auto;
}

.add_new_artwork_select_size::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #F5F5F5;
} 

.add_new_artwork_select_size::-webkit-scrollbar-thumb {
  background-color: #ff9100e8;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #ff9100e8 !important;
  border-color: #ff9100e8 !important;
}

.add_new_artwork_select_size_item{
  width: 120px;
  height: 120px;
  max-width: 120px;
  min-width: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  overflow: hidden;
  border: 1px solid #e1e1e1;
}
.add_new_artwork_select_size_item_label{
  position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ff9100e8;
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
}

.list-type-image-generation::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.list-type-image-generation::-webkit-scrollbar
{
	width: 12px;
  height: 7px;
	background-color: #F5F5F5;
}

.list-type-image-generation::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #ff9100;
	background-color: #ff9100;
}

.progress-box {
  height: 15px;
  background: #ebebeb;
  border-radius: 45px;
  // margin-bottom: 5px;
  position: relative;
  display: flex;
  width: 100%;
}
.progress-loading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 45px;
  transition: 0.5s;
  background: rgb(255,240,0);
  background: linear-gradient(90deg, rgba(255,240,0,1) 0%, rgba(255,166,0,1) 52%, rgba(255,102,0,1) 100%);
}

.progress-view-text{
  margin: auto;
  position: absolute;
  left: 0;
  right: 00;
  text-align: center;
  font-size: 9px;
  color: #fff;
  top: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.option-item-midjourney{
  background: rgba(0, 0, 0, 0.85);
  color: rgb(255, 255, 255);
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-types-arrow {
  background: white;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-content: stretch;
  padding: 2px;
  cursor: pointer;
}

ul.list-descriptions-ai-image {
  padding: 10px;
  border-radius: 2px;
  background: #fff;
  list-style: none;
  font-family: system-ui;
  font-size: 15px;
}

ul.list-descriptions-ai-image > li {
  margin-bottom: 10px;
}

.pagination-types {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 5px;
  border-radius: 3px;
}

.slider-container-history {
  width: 100%;
  height: 500px;
  overflow: hidden;
  position: relative;
  // margin-bottom: 10px;
  border-radius: 1px;
  box-shadow: 0px 0px 5px -1px black;
}



.slider-history {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

.slide-history {
  height: 500px;
  flex-shrink: 0;
  text-align: center;
  padding: 35px;
  position: relative;
}

.btn-delet-item-history {
  background-color: red;
  color: #ffffff;
  aspect-ratio: 1 / 1;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  box-shadow: 0px 0px 5px #000;
  cursor: pointer;
}

ul.slide-history-list {
  list-style: none;
  padding: 0;
  text-align: left;
}

.slide-history-item {
  height: 100%;
  overflow: hidden;
  position: relative;
}

img.slide-history-img {
  height: 100%;
  border-radius: 5px;
  width: auto !important;
}

ul.slide-history-list {
  list-style: none;
  padding: 0;
  text-align: left;
  height: 100%;
  width: 100%;
  transform: scale(0.9);
  margin: auto;
}

.box-slide-history-choose {
  position: absolute;
  left: 0;
  right: 0;
  // top: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.group-btn-slider > button {
  width: 100%;
  margin: 0px !important;
  border: none;
  padding: 5px;
  color: #fff;
  FONT-SIZE: 21px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-slide-history-choose > button {
  background: #fff;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 90px;
  font-weight: bold;
  border-radius: 45px;
  color: #ff9100;
  transition: 0.5s;
}

.box-slide-history-choose > button:hover{
  background: #ff9100;
  color: #ffffff;
  transition: 0.5s;
}

.group-btn-slider {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // flex-direction: column;
  // top: 0;
}

.group-btn-slider > button#prev{
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(0deg, #18181800 0%, #18181842 100%)
}

.group-btn-slider > button#next{
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(180deg, #18181800 0%, #18181842 100%)
}

#prev {
  margin-top: 5px;
}

#next{
  margin-bottom: 5px;
}

.model-history .ant-modal-header {
  display: none;
}
.model-history .ant-modal-body{
  padding: 0px !important;
}

.model-history  .ant-modal-footer {
  display: none;
  padding: 0;
}

.clock-history-btn {
  margin-right: 5px;
  cursor: pointer;
  background: #ff9100;
  height: 27px;
  min-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  min-height: 23px;
  color: #fff;
  z-index: 99;
  padding: 10px;
  font-size: 16px;
}

.clock-history-btn-kling {
  margin-right: 5px;
  cursor: pointer;
  background: #ff9100;
  height: 27px;
  min-width: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  min-height: 23px;
  color: #fff;
  z-index: 99;
  padding: 5px;
  font-size: 16px;
  width: fit-content;
}

.box-progress-history{
  width: 100%;
  display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background: #fff;
    padding: 3px;
    position: relative;
    // margin-bottom: 5px;
    border: 3px solid #ff9100;
    border-radius: 36px;
    // padding-left: 12px;
}

main.ant-layout-content {
  background: #fff;
}


.trash-all-history {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  z-index: 999999;
}
.btn-trash-all-history {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 29px;
  color: #fff;
  background: red;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  opacity: 0.4;
  font-size: 14px;
  transition: 0.6s;
}

.btn-trash-all-history:hover{
  opacity: 1;
  transition: 0.6s;
}

.loadingGeneration{
  opacity: 0.3;
  pointer-events: none;
  cursor: none;
}

.box-loading-generation{
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  flex-direction: column;
  background: #fff;
  width: 400px;
  margin: auto;
  border: 1px solid #cfcfcf;
  max-width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #c5a47757;
}

.kling-group-item {
  width: 50%;
  padding: 3px;
}

.kling-group {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  // padding: 10px;
  height: 100%;
  align-content: flex-start;
}

.kling-group-video {
  padding: 5px;
}

img.kling-group-item__img, video.kling-group-video-item__img {
  width: 100%;
  border-radius: 5px;
}

.kling-group-image-history.kling-group{
  width: auto;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-items: center;
  margin: auto;
}

.kling-group-image-preview .kling-group-item {
  height: 50%;
  width: auto;
}

.kling-group-describe-item{
  height: 500px;
  overflow: auto;
  white-space: break-spaces;
  border: 1px solid #e3e3e3;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  text-align: left !important;
}

.progress-grooup-loading {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.progress-box-kling {
  position: relative;
  height: 12px;
  background: #c7c7c7;
  border-radius: 50px;
  width: 100%;
  margin-right: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.progress-box-de-kling {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #ff9100;
  border-radius: 50px;
  transition: 0.6s;
}

.progress-text-kling {
  color: #ff9100;
  font-weight: 600;
}

.btn-cancel {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  background: #535353;
  color: #e3e3e3;
  font-weight: 600;
}

.btn-cancel:hover{
  background: #535353;
  color: #e3e3e3 !important;
}

.group-preview-chatgpt{
  margin-left: 10px;
}

.group-preview-chatgpt__control{
  margin-bottom: 5px;
}

.btn-delete,
.btn-delete:hover{
  color: brown;
  border-radius: 5px;
}



.message-group {
  list-style: none;
  padding: 0px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  max-height: 610px;
  overflow: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;
}

li.message-item-user {
  display: flex;
  justify-content: flex-end;
}

li.message-item-user .message-item-content{
  background: #e7e7e7;
  padding: 10px;
  border-radius: 5px;
}

ul.message-group > li {
  margin: 5px 5px;
}

li.message-item-assistant .message-item-content {
  white-space: break-spaces;
  background: #f5f5f5;
  padding: 10px;
  width: fit-content;
  max-width: 100%;
  border-radius: 5px;
}

li.message-item-assistant {
  display: flex;
  justify-content: flex-start;
}

.message-item-content pre {
  // background: #333;
  // color: #fff;
  // padding: 10px;
  border-radius: 5px;
  // white-space: pre-line;
  width: 100%;
}

.message-item-content * {
  margin: 0;
  line-height: 1.4;
}

.message-group ol > li > *:first-child{
  margin-top: -19px;
}
.message-item-assistant .message-item-content > *:last-child{
  margin-bottom: -20px;
  }

  .custom-list p {
    margin: -13px 0 !important;
}

ul.custom-list {
  padding: 0px 15px;
}
.message-item-assistant .message-item-control-group{
  width: 100%;
}
.message-item-control-group{
  position: relative;
  
}

.message-item-control{
  position: sticky;
  right: 0;
  display: flex;
  // margin-top: 5px;
  padding: 5px 0;
  bottom: 0px;
  justify-content: flex-end;
}
.message-item-control-assistant{
  position: sticky;
  left: 6px;
  padding: 5px 0;
  bottom: 0px;
  z-index: 999;
  align-items: center;
  display: flex;
}
.message-item-control-group:hover .message-item-control,
.message-item-control-group:hover > .message-item-control-assistant
{
  display: flex;
}

button.btn-control-chat {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  height: 23px;
  width: 23px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 3px;
}

.modal-preview-image .ant-modal-header {
  display: none;
}

.modal-preview-image  .ant-modal-body {
  padding: 5px;
}

.information-item {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  z-index: 999999;
}

.container_infomation {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  z-index: 999999;
  background: #ffffff00;
  padding: 0px;
  left: 0;
}

.btn_open_info {
  z-index: 9999999;
  border-radius: 30px;
}

.container_infomation__ul {
  width: 300px;
  background: #ffffffe0;
  padding: 10px;
  list-style: none;
  text-align: left;
  font-size: 14px;
  border-radius: 5px;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 0px 10px -5px;
}

li.li-select-background-autobanner {
  position: relative;
  margin-bottom: 5px;
  width: 70px;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  height: 70px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}

li.li-select-background-autobanner span.ant-checkbox {
  z-index: 999;
}

li.li-select-background-autobanner .ant-checkbox {
  position: absolute;
  top: 2px;
  right: 2px;
}

li.li-select-background-autobanner .ant-checkbox + span {
  padding-right: 0px;
  padding-left: 0px;
}
.collapse-background-auto-banner .ant-collapse-header, 
.collapse-background-auto-banner .ant-collapse-content-box{
  padding: 5px 0 !important;
}

.disabled-box{
  opacity: 0.2;
  pointer-events: none;
}
.btn-upload-img-autobanner > span{
  display: block;
  width: 100%;
}

.btn-upload-img-autobanner *{
  background: none !important;
  border: none !important;
  color: #fff;
}

.slide-history-list-autobanner{
  list-style: none;
  padding: 0;
  text-align: left;
  height: 100%;
  width: 100%;
  transform: scale(0.9);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
}


.slide-history-list-autobanner::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.slide-history-list-autobanner::-webkit-scrollbar
{
	width: 5px;
  height: 5px;
	background-color: #F5F5F5;
}

.slide-history-list-autobanner::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #ff9100;
}

.slider-history.autobanner .slide-history{
  padding: 20px;
}

.btn-choose-color-picker{
  height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 1px dotted #ff9100;
    cursor: pointer;
}

.ant-modal.modal-color-picker .ant-modal-body {
  padding: 0;
}

.ant-modal.modal-color-picker .ant-modal-footer {
  display: none;
}

.ant-modal.modal-color-picker .ant-modal-close-x {
  display: none;
}

.scrabble_font .fontWidth {
  display: none;
}


li.li-select-background-autobanner {
  position: relative;
  margin-bottom: 5px;
  width: 70px;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  height: 70px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}

li.li-select-background-autobanner span.ant-checkbox {
  z-index: 999;
}

li.li-select-background-autobanner .ant-checkbox {
  position: absolute;
  top: 2px;
  right: 2px;
}

li.li-select-background-autobanner .ant-checkbox + span {
  padding-right: 0px;
  padding-left: 0px;
}
.ant-row.row-add-tag {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 0;
}
input.input-add-tag {
  width: 100%;
  min-width: 100px;
  flex: 1;
  border: none;
  outline: none;
  height: 22px;
  margin: 0;
  margin-right: 5px;
  margin-bottom: 5px;
}
span.ant-tag.tag-item {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}
.ant-collapse-item-active .abc{
  font-weight: bold !important;
}
.modal_setting_crossword .ant-modal-body{
  height: calc(100vh - 200px);
  overflow: hidden;
}
.card-settingt-srabble > * {
  padding: 5px 10px 0px 10px;
}


.calendar-layer-choose-image .ant-upload.ant-upload-drag{
  width: 80px !important;
  height: 80px !important;
  overflow: hidden;
  display: flex;
}

.calendar-layer-choose-image .ant-upload.ant-upload-drag > span{
  padding: 0px !important;
  overflow: hidden;
}

.calendar-layer-choose-image .ant-upload.ant-upload-drag span.ant-avatar{
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-layer-choose-image .ant-upload.ant-upload-drag span.ant-avatar img{
  width: 100% !important;
  height: auto !important;
}

.scrabble_font .fontWidth {
  display: none;
}

.btn-choose-color-picker{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px dotted #ff9100;
  cursor: pointer;
}


.ant-modal.modal-color-picker .ant-modal-body {
  padding: 0;
}

.ant-modal.modal-color-picker .ant-modal-footer {
  display: none;
}

.ant-modal.modal-color-picker .ant-modal-close-x {
  display: none;
}

.modal_setting_crossword .ant-modal-body{
  height: calc(100vh - 200px);
  overflow: hidden;
}

.image-calendar-preview{
  border-radius: 5px;
  background-image: linear-gradient(45deg, #999 25%, #00000030 25%, #00000030 75%, #999 75%, #999), linear-gradient(45deg, #999 25%, #00000030 25%, #00000030 75%, #999 75%, #999);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.dpad {
  position: relative;
  width: 80px;
  height: 80px;
  // background-color: #000; /* D-pad background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Common style for directional buttons */
.btn-directional-pad {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

button.btn-directional-pad{
  background: #ff9100;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: 0.6s;
}

/* Clip-path for each button */
/* Up Button */
.dpad-up button {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  clip-path: polygon(100% 0%, 100% 50%, 50% 100%, 50% 100%, 0 50%, 0 0);
}

/* Down Button */
.dpad-down button {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  clip-path: polygon(50% 0, 100% 50%, 100% 100%, 0 100%, 0 50%, 50% 0);
}

/* Right Button */
.dpad-right button {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  clip-path: polygon(50% 0, 100% 0%, 100% 100%, 50% 100%, 0% 50%);
}

/* Left Button */
.dpad-left button {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  clip-path:  polygon(0% 0%, 50% 0, 100% 50%, 50% 100%, 0% 100%);
}

/* Optional: Hover effect */
.btn-directional-pad:hover {
  background-color: #c78500;
  transition: 0.6s;
}
.custom-margin .ant-col.ant-form-item-label{
  margin-bottom: 0px;
  line-height: 10px !important;
}